<template>
    <div>
        <template v-if="loading">
            <div class="alert alert-info text-center mb-1"><i class="fas fa-spinner fa-spin"></i></div>
        </template>
        <template v-else>
            <div @click="showPhoto = true" class="text-center" style="font-size: 18px;"><b>{{nomenclatureName}}</b></div>
            <hr>
            <b-form-group>
                <input v-model="count" type="number" min="1" step="1" class="form-control col-6 m-auto text-center" style="height: unset; font-size:50px; line-height: 51px;">
            </b-form-group>
            <hr>
            <div class="alert" :class="{'alert-danger': packed > amount, 'alert-light': packed <= amount}">
                <span>Упаковано: {{packed}} из
                    <i v-if="loadingAmount" class="fas fa-spinner fa-spin"></i>
                    <template v-else>{{amount}}</template>
                </span>
            </div>
            <hr>
            <div v-for="content in contents"
                 @dblclick="detach(content)"
                 :id="'content_'+content.id"
                 :key="content.id"
                 class="alert alert-dark mb-1 box-item">
                <span v-if="content.wasPlacedAnyWhere" class="mr-1"><i class="fas fa-pallet"></i></span>
                <span>{{content.box.barcode.code}}</span>
                <span class="pull-right">
                    <span v-if="!(!!content.detaching)">{{content.count}}</span>
                    <span v-else><i class="fas fa-spinner fa-spin"></i></span>
                </span>
            </div>
            <div class="mb-3"></div>
        </template>
        <modal name="photo"
               @before-close="showPhoto = false"
               :adaptive="true"
               :scrollable="true"
               :height="'auto'"
               :maxHeight="300">
            <div v-if="showPhoto && nomenclatureLotId" class="text-center">
                <div class="card mb-0">
                    <div class="card-header">
                        <button class="btn pull-right" @click="$modal.hide('photo')"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="card-body" style="padding: 0 !important; overflow-x: scroll;">
                        <img :src="nomenclatureImage" v-if="nomenclatureImage">
                        <i v-else class="fas fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import {PACKING_ENDPOINT} from "../../../utils/endpoints";
    import {sumBy, findIndex} from 'lodash';
    import Barcode from "../../../utils/Barcode";

    const BOX_BARCODE_PREFIX = Barcode.boxBarcodePrefix();

    const WITH = [
        'box.barcode',
        'nomenclature',
        'nomenclatureLot',
    ];

    export default {
        name: "Packing",
        props: {
            productionPlanIds: {
                type: Array,
                required: true,
            },
            nomenclatureLotIds: {
                type: Array,
                required: true,
            },
            nomenclatureLotId: {
                type: Number,
                required: true,
            },
            nomenclatureId: {
                type: Number,
                required: true,
            },
            nomenclatureName: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                count: 40,
                amount: null,
                loadingAmount: false,
                contents: [],
                showPhoto: false,
                loading: false,
            };
        },
        watch: {
            productionPlanIds(val) {
                if (val) {
                    this.load();
                }
            },
            nomenclatureLotId(val) {
                if (val) {
                    this.$store.dispatch('nomenclature/info/getNomenclatureInfo', this.nomenclatureId);
                }
            },
            showPhoto(val) {
                if (val) {
                    this.$modal.show('photo');
                } else {
                    this.$modal.hide('photo');
                }
            }
        },
        computed: {
            nomenclatureImage() {
                return this.$store.getters['nomenclature/info/image'](this.nomenclatureId)
            },
            packed() {
                return sumBy(this.contents, c => parseFloat(c.count));
            },
        },
        methods: {
            load() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.$http.get(PACKING_ENDPOINT + '/contents', {
                    params: {
                        nomenclatureLotIds: this.nomenclatureLotIds,
                        with: WITH,
                        without_loading: true,
                    }
                }).then(response => {
                    this.contents = response.data;
                }).catch(response => {
                    this.$toast.error(response.data.message);
                }).finally(() => {
                    this.loading = false;
                    this.$emit('loaded');
                });

                this.loadAmount();
            },
            loadAmount() {
                if (this.loadingAmount) {
                    return;
                }

                this.loadingAmount = true;
                this.$http.get(PACKING_ENDPOINT + `/amount`, {
                    params: {
                        productionPlanIds: this.productionPlanIds,
                        without_loading: true,
                    }
                }).then(response => {
                    this.amount = parseFloat(response.data);
                }).catch(response => {
                    this.$toast.error(response.data.message);
                }).finally(() => {
                    this.loadingAmount = false;
                });
            },
            onBarcodeScanned(data) {
                if (!data.barcode.startsWith(BOX_BARCODE_PREFIX)) {
                    this.$toast.error('Неверный ШК. Отсканируйте ШК ящика');
                    return;
                }
                this.attach(data.barcode);
            },
            attach(barcode, force = false) {
                if (this.attaching) {
                    this.$toast.error('Дожитесь завершения предыдущей операции');
                    return;
                }

                if (parseFloat(this.count) <= 0) {
                    this.$toast.error('Указано неверное количество');
                    return;
                }

                this.attaching = true;

                this.$http.post(PACKING_ENDPOINT + '/attach', {
                    nomenclatureId: this.nomenclatureId,
                    nomenclatureLotId: this.nomenclatureLotId,
                    barcode: barcode,
                    count: this.count,
                    force: force ? 1 : 0,

                    with: WITH,
                }, {params: {without_loading: true}}).then(response => {
                    if (response.data.success) {
                        if  (force) {
                            this.deleteFromList(response.data.content);
                            this.$toast.success('Содержимое ящика заменено');
                        }
                        this.contents.unshift(response.data.content);
                    } else {
                        let content = response.data.content;
                        this.$toast.warning(`${content.count}шт<br> "${content.nomenclature.name}"`, 'Ящик занят', {
                            timeout: 10000,
                            buttons: [
                                [
                                    '<button class="btn btn-danger">Заменить содержимое</button>',
                                    (instance, toast) => {instance.hide({},toast); this.attach(barcode, true);}
                                ]
                            ]
                        });
                    }
                }).catch(response => {
                    let message = '';
                    if (response.status === 422 && !!response.data.errors) {
                        Object.keys(response.data.errors).map(k => {
                            response.data.errors[k].map(m => {
                                if (typeof m === 'string') {
                                    message += m+"<br>";
                                }
                            });
                        });
                    } else {
                        message = response.data.message;
                    }
                    this.$toast.error(message);
                }).finally(() => {
                    this.attaching = false;
                });
            },
            detach(content) {
                if (!window.confirm(`Очистить ящик ${content.box.barcode.code}?`)) {
                    return;
                }
                if (!!content.detaching) {
                    return;
                }
                content.detaching = true;
                this.$forceUpdate();

                this.$http.put(PACKING_ENDPOINT + `/detach/${content.id}`, {}, {params: {without_loading:true}})
                    .then(response => {
                        this.$bvToast.toast(`Ящик ${content.box.barcode.code} освобожден`, {variant: 'success'});
                        this.deleteFromList(content);
                    }).catch(response => {
                        this.$bvToast.toast(response.data.message, {variant: 'danger'});
                    }).finally(() => {
                        content.detaching = false;
                        this.$forceUpdate();
                    });
            },
            deleteFromList(content) {
                let index = findIndex(this.contents, c => c.box.id === content.box.id);
                if (index !== -1) {
                    this.contents.splice(index, 1);
                }
            }
        },
        mounted() {
            this.$root.listenToBarcodeScanning(this);
            this.load();
            this.$store.dispatch('nomenclature/info/getNomenclatureInfo', this.nomenclatureId);
        },
        beforeDestroy() {
            this.$emit('finish', {
                count: this.packed,
                nomenclature_lot_id: this.nomenclatureLotId,
            });
        }
    }
</script>

<style scoped>
    .box-item {
        animation: boxitem 1s ease-in-out;
    }

    @keyframes boxitem {
        0% {
            background-color: #dbf2e3;
        }

        100% {
            background-color: #d5d7d8;
        }
    }
</style>
